import { createApp } from 'vue';
import { Modal, Button } from 'ant-design-vue';
import App from './App.vue';
import router from './router/index';
import './index.css';

createApp(App)
    .use(router)
    .use(Modal)
    .use(Button)
    .mount('#app')
