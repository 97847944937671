const routes = [
    {
        path: '/',
        component: () => import('@/views/Layout.vue'),
        redirect: '/home',
        children: [
            {
                path: '/home',
                name: 'Home',
                component: () => import('@/views/Home.vue')
            },
            {
                path: '/help',
                name: 'Help',
                component: () => import('@/views/Help.vue')
            }
        ]
    },
    {
        name: 'signin',
        path: '/signin',
        component: () => import('@/views/Signin.vue')
    }
]

export default routes
