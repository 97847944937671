<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
};
</script>

<style>
.ant-message-custom-content > * {
  vertical-align: middle;
}
#app {
  height: 100%;
  font-family: Myriad Pro,PingFang SC,Helvetica Neue,Helvetica,Arial,sans-serif;
}
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>
